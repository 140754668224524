import styled from "styled-components"

export const Title = styled.h1`
  font-weight: 700;
  font-size: 22px;
  margin: 0 0 24px;

  @media (min-width: 1024px) {
    font-size: 40px;
  }
`;

export const Main = styled.main`
  flex: 1;
  .bgright{
    position:absolute;
    height:400px;
    bottom:78px;
    right:0;
    z-index:-1;
  }
  .bgleft{
    position:absolute;
    height:400px;
    top:0;
    left:0;
    z-index:-1;
  }
`;

export const FooterWrap = styled.footer`
  background-color: #f0f7f7;
  border-top: 2px solid rgba(0,0,0,.1);;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
`;

export const CardTitle = styled.h6`
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  min-height: 50px;
`;

export const CardCategory = styled.span`
  color: #bdbdbd;
  font-size: 14px
`;

export const HeroSection = styled.section`
  background-image: url("https://themes.pixelstrap.com/unice/assets/images/saas2/slider.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--theme-color);
  color: #fff;
  font-size: 36px;
  height: 70vh;
  margin-bottom: 40px;
  min-height: 400px;
  text-align: center;
  
  @media (min-width: 1024px) {
    background-position: 0 -100px;
    font-size: 72px;
    height: 90vh;
    text-align: left;
  }
  
  @media (min-width: 2100px) {
    background-position: 0 -200px;
  }

  strong {
    @media (min-width: 1024px) {
      margin-left: 184px;
    }
  }
`;

export const DetailsSection = styled.section`
  margin-bottom: 80px;
  
  @media (min-width: 768px) {
    margin-bottom: 160px
  }
`;

export const HeaderWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & > a:hover {
    text-decoration: none
  }

  nav {
    a {
      border-radius: 4px;
      color: #000;
      margin-left: 16px;
      padding: 8px 12px;
      transition: 80ms;
      
      &:hover {
        background-color: rgba(0 0 0 / 0.1);
        text-decoration: none;
      }
    }
  }
`;

export const Brand = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 51px;
  line-height: 1;

  img {
    filter: drop-shadow(2px 2px 0px rgba(255,255,255,.4));
    height: 24px;
    transition: 80ms;

    @media (min-width: 768px) {
      height: 40px;
    }
  }

  & > div {
    display: inline-flex;
  }

  div span {
    color: #0775d7;
    font-weight: bold;
    vertical-align: middle;
  }

  div + span {
    font-family: "Suez One";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 6px;
    margin-top: 4px;
    text-shadow: 2px 2px 0px rgba(0,0,0,.2);

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
`;

export const ProductInfos = styled.div`
  figure {
    margin-bottom: 24px;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  .info-name {
    line-height: 1;
  }

  .info-category {
    color: #666
  }

  .info-price {
    background: linear-gradient(to right, #00A4E5 0%, #0F68CA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    font-weight: bold;
    line-height: 48px;
    margin-top: 16px;
  }

  dt,
  u {
    font-size: 14px;
    font-weight: 100;
    text-decoration: underline;
  }

  dd {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    text-shadow: 0px 0px 4px rgba(0 0 0 / 0.1);
  }
`;

export const ViewAllProducts = styled.div`
  margin-top: 40px;
  text-align: center;

  @media (min-width: 1024px) {
    margin-top: 56px;
  }

  @media (min-width: 2100px) {
    margin-top: 240px;
  }

  a {
    align-items: center;
    background-color: #fff;
    border: $border;
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    display: inline-flex;
    font-weight: 400;
    font-size: 14px;
    justify-content: center;
    margin-right: 4px;
    min-width: 64px;
    outline: none;
    line-height: 24px;
    padding: 11px 32px 11px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: 0.1s ease-out;
    user-select: none;

    &:hover {
      transform: scale(1.05)
    }
  }

  svg {
    animation-duration: 2s;
    animation-name: arrow;
    animation-iteration-count: infinite;
    position: absolute;
    right: 16px
  }
`;