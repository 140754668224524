import React from "react"
import { FooterWrap } from "./Styled"

const Footer = () => {
  return (
    <FooterWrap>
      <div className="container">
      
        <span>
          © {new Date().getFullYear()} | 3D Bilim
        </span>
        
      </div>
    </FooterWrap>
  )
}

export default Footer