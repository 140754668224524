import React from "react"
import LayoutTemp from "./Layout"
import SEOTemp from "./Seo"
import ProductsItemCardTemp from "./ProductsItemCard"

export function Layout({ children }) {
  return (
    <LayoutTemp content={children} />
  )
}

export function SEO({ title }) {
  return (
    <SEOTemp title={title} />
  )
}

export function ProductsItemCard({ productItems }) {
  return (
    <ProductsItemCardTemp productItems={productItems} />
  )
}