import React, { Component } from "react"
import Swiper from "react-id-swiper"
import { Link } from "react-router-dom"
import { CardTitle, CardCategory } from "./Styled"

class Slider extends Component {
  componentDidUpdate() {
  }
  
  render () {
    const { products, title } = this.props
    
    const items = products.map(({name, code, category, price, links}, i) =>   
      <div className="card is-small" key={i}>
        <div className="card-image">
          <Link to={`/${links.category}/${links.product}`}>
            <figure>
              {
                code
                ? <img data-src={require(`../img/${code}.jpg`)} alt={name} className="swiper-lazy" />
                : <img data-src={`https://picsum.photos/400/400?random=${i}`} alt={name} className="swiper-lazy"/>
              }
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            </figure>
          </Link>
        </div>

        <div className="card-content">
          <Link to={`/${links.category}/${links.product}`}>
            <CardTitle>{name}</CardTitle>
          </Link>

          <div className="d-flex justify-between">
            <CardCategory>{category}</CardCategory>
            <span>{price}</span>
          </div>
        </div>
      </div>
    )

    var params = {
      containerClass: "swiper-container pt-2",
      breakpoints: {
        1140: {
          slidesPerView: 4,
          spaceBetween: 24,
          slidesPerView: 4.2
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 24,
          slidesPerView: 3.2
        },
        720: {
          slidesPerView: 2,
          spaceBetween: 16,
          slidesPerView: 2.2
        },
        540: {
          slidesPerView: 2,
          spaceBetween: 16,
          slidesPerView: 2.2
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 16,
          slidesPerView: 1.1
        }
      },
      lazy: true,
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        dynamicBullets: true
      },
    };

    return (
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title is-size-4 mb-4">{title}</h2>

                <Swiper {...params} className="pt-5">
                  {items}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Slider