import React, { Component } from "react"
import Details from "./details/Details"
import SimilarSlider from "./details/SimilarSlider"
import productItems from "./../productsItems.json"

class ProductDetails extends Component {
  render () {
    /**
     * Linki çeker ve parçalara ayırır
     * /kalem-susleri/astronot
     */
    const productLink = window.location.pathname.replace("/","").split("/")

    /**
     * Linkten başlık ve kategorisi ayırır
     * productCategory: kalem-susleri
     * productTitle: astronot
     */
    const productCategory = productLink[0]
    const productTitle = productLink[1]

    /**
     * Seçilen ürünü tüm ürünler arasında filtreleyerek bulur
     */
    const selectProduct = productItems.filter(e => productTitle === e.links.product && productCategory === e.links.category)

    /**
     * Seçilen ürün ile aynı kategorideki diğer ürünleri getirir
     */
    const productSimilar = productItems.filter(e => productTitle !== e.links.product && productCategory === e.links.category)

    return (
      <>
        <Details product={selectProduct[0]} />
        <SimilarSlider productSimilar={productSimilar} />
      </>
    )
  }
}

export default ProductDetails