import React, { Component } from "react"
import { Link } from "react-router-dom"
import { SEO, ProductsItemCard } from "../components"
import SeoLink from "./../components/SeoLink"
import { CardTitle, CardCategory } from "./../components/Styled"

import productItems from "../productsItems.json"

class Categories extends Component {
  render () {
    const category = productItems.groupBy("category")
    let categoryArr = []
    let categoryArr2 = []
    let items = ""

    for (let i = 0; i < Object.keys(category).length; i++) {
      categoryArr.push(category[Object.keys(category)[i]])
    }

    categoryArr.map((e, i) => {
      items += `<p>${categoryArr[i][0].category}</p>`

      for (let j = 0; j < Object.keys(categoryArr[0].groupBy("subCategory")).length; j++) {
        categoryArr2.push(categoryArr[j].groupBy("subCategory")[Object.keys(categoryArr[j].groupBy("subCategory"))[j]])
      }
    })

    return (
      <section>
        <div className="container">
          
          <SEO title="Kategoriler" />

          <div className="row">
            <div className="col-12">
              <h2 className="title is-size-4">Kategoriler</h2>

              <div dangerouslySetInnerHTML={{__html: items}}></div>

            </div>
          </div>

        </div>
      </section>
    )
  }
}

export default Categories