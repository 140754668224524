import React, { Component } from "react"
import Slider from "./../../components/Slider"

class ProductDetails extends Component {
  render () {
    const productSimilar = this.props.productSimilar

    return (
      <>
        <Slider
          title="Benzer Ürünler"
          products={productSimilar}
        />
      </>
    )
  }
}

export default ProductDetails