import React from "react"
import { Link } from "react-router-dom"
import { HeroSection, ViewAllProducts } from "./../../components/Styled"
import TypeIt from "typeit-react";

const Hero = () => {
  return (
    <>
      <HeroSection>
        <div className="container">

          <TypeIt
            getBeforeInit={instance => {
            instance
              .type("Siz hayal edin,")
            return instance;
            }}
            options={{
              speed: 60,
              waitUntilVisible: true
            }}
          />

          <TypeIt
            getBeforeInit={instance => {
            instance
              .pause(1500)
              .type("<strong>3D Bilim</strong> üretsin!")
            return instance;
            }}
            options={{
              speed: 80,
              waitUntilVisible: true
            }}
          />
        </div>

        <ViewAllProducts>
          <Link to="/urunler">
            <span>Tüm Ürünleri Gör</span>

            <svg aria-hidden="true" role="img" viewBox="0 0 320 512" height="12px"><path fill="#000" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
          </Link>
        </ViewAllProducts>
      </HeroSection>
    </>
  )
}

export default Hero