import React, { Component } from "react"

import { SEO, ProductsItemCard } from "./../components"

import productItems from "./../productsItems.json"

class Products extends Component {
  render () {
    return (
      <section>
        <div className="container">
          
          <SEO title="Ürünler" />

          <div className="row">
            <ProductsItemCard productItems={productItems} />
          </div>

        </div>
      </section>
    )
  }
}

export default Products