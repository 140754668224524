import React, { Component } from "react"
import { Link, NavLink } from "react-router-dom"
import { HeaderWrap, Brand } from "./Styled"

class Header extends Component {
  componentDidMount() {
    var header = document.querySelectorAll("header")[0];

    window.addEventListener("scroll", function() {
        var scroll = window.pageYOffset || document.documentElement.scrollTop;
        if (scroll >= 75) {
            header.classList.add("is-active");
        } else {
            header.classList.remove("is-active");
        }
    });
  }

  render () {
    let nav = links.map(({link, text}, i) =>
      <NavLink to={link} exact key={i}>
        {text}
      </NavLink>
    )

    const colorArr = ["#f44336", "#e91e63", "#9c27b0", "#3f51b5", "#2196f3", "#00bcd4", "#009688", "#4caf50", "#ff5722", "#795548", "#607d8b", "#000"]
    const randomColor = colorArr[Math.floor(Math.random() * colorArr.length)]
    var html = document.getElementsByTagName('html')[0];
    html.style.cssText = `--theme-color:${randomColor}`;
    return (
      <header>
        <HeaderWrap className="container">
          <Link to="/">
            <Brand className="brand">
              <div>
                <img src="https://cdn.discordapp.com/attachments/700297353575530519/723193524698480641/Varlk_4300x.png" alt="3D Bilim Logo" height="40" />
              </div>
              <span style={{"color": randomColor}}>Bilim</span>
            </Brand>
          </Link>
          
          
          <nav>
            {nav}
          </nav>
          
        </HeaderWrap>
      </header>
    )
  }
}

export const links = [
  {
    text: "Ana Sayfa",
    link: "/"
  },
  {
    text: "Ürünler",
    link: "/urunler"
  }
]

export default Header