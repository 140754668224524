import React from "react"
import Slider from "./../../components/Slider"
import productsItems from "./../../productsItems.json"

const Opportunity = () => {
  var array = productsItems.filter(e => e.opportunity)
  var currentIndex = array.length, temporaryValue, randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  const opportunityItems = array
  
  return (
    <>
      
      <Slider
          title="Günün Fırsatları"
          products={opportunityItems}
        />
    </>
  )
}

export default Opportunity