import React from "react"
import { SEO } from "./../components"
import Hero from "./home/Hero"
import Opportunity from "./home/Opportunity"

const Home = () => {
  return (
    <>
      <SEO title="Ana Sayfa" />
      
      <Hero />
      <Opportunity />
    </>
  )
}

export default Home