import React from "react"
import { Link } from "react-router-dom"
import { CardTitle, CardCategory } from "./Styled"

const ProductsItemCard = ({ productItems }) => {  
  const items = productItems.map(({name, code, category, price, links}, i) =>
    <div className="col-12 col-md-4 col-lg-3" key={i}>     
      <div className="card is-small">
        <div className="card-image">
          <Link to={`/${links.category}/${links.product}`}>
            <figure>
              {
                code
                ? <img src={require(`../img/${code}.jpg`)} alt={name} />
                : <img src={`https://picsum.photos/400/400?random=${i}`} alt={name}/>
              }
            </figure>
          </Link>
        </div>

        <div className="card-content">
          <Link to={`/${links.category}/${links.product}`}>
            <CardTitle>{name}</CardTitle>
          </Link>

          <div className="d-flex justify-between">
            <CardCategory>{category}</CardCategory>
            <span>{price}</span>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
     {items}
    </>
  )
}

export default ProductsItemCard