import React from "react"
import HomePage from "./Home"
import ProductsPage from "./Products"
import ProductDetailsPage from "./ProductDetails"
import CategoriesPage from "./Categories"

export function Home() {
  return (
    <HomePage />
  )
}

export function Products () {
  return (
    <ProductsPage />
  )
}

export function ProductDetails () {
  return(
    <ProductDetailsPage />
  )
}

export function Categories () {
  return(
    <CategoriesPage />
  )
}