import React, { Component } from "react"
import { Link } from "react-router-dom"
import { SEO } from "./../../components"
import { DetailsSection, ProductInfos } from "./../../components/Styled"

class Details extends Component {
  render () {
    const { name, code, category, price } = this.props.product

    let cargo = this.props.product.cargo || 7
    let infos = this.props.product.infos || ""

    const currentDate = new Date()
    const cargoDate = currentDate.toTurkishFormatDate("dd MM")

    let nextDate = new Date()
    nextDate.setDate(currentDate.getDate() + cargo)

    const cargoDateNext = nextDate.toTurkishFormatDate("dd MM")

    let cargoText = ""
    
    cargoDate.split(" ")[1] === cargoDateNext.split(" ")[1]
      ? cargoText = `${cargoDate.split(" ")[0]} - ${cargoDateNext}`
      : cargoText = `${cargoDate} - ${cargoDateNext}`

    let infoText = ""

    infoText = infos !== ""
      ? `<u>Ürün Bilgileri</u><p>${infos}</p>`
      : ""

    return (
      <>
        <SEO title={name} />
      
        <DetailsSection>
          <div className="container mb-5">
            <div className="row">
              <div className="col-12">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/">Ana Sayfa</Link>
                  </li>
                  <li>
                    <Link to="/urunler">Ürünler</Link>
                  </li>
                  <li>
                    <span>{category}</span>
                  </li>
                  <li>
                    <span>{name}</span>
                  </li>
                </ul>
              </div>

              <div className="col-12">
                <ProductInfos>
                  <div className="row">
                    <div className="col-12 col-md-6">
                    <figure>
                      {
                        code
                        ? <img src={require(`../../img/${code}.jpg`)} alt={name} />
                        : <img src={`https://picsum.photos/1200/1200`} alt={name}/>
                      }
                      
                    </figure>
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="box mb-4">
                        <h1 className="info-name title is-size-3">
                          {name}
                        </h1>

                        <span className="info-category">
                          {category}
                        </span>

                        <div className="info-price">
                          {price.replace("₺", "")} TL
                        </div>
                      </div>

                      <div className="box pb-1">
                        <dl>
                          <dt>Ürün Kodu</dt>
                          <dd>{code}</dd>

                          <dt>Tahmini Kargoya Veriliş Süresi</dt>
                          <dd className="pr-0">{cargoText}</dd>
                          
                          <div dangerouslySetInnerHTML={{__html: infoText}}></div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </ProductInfos>
              </div>
            </div>
          </div>
        </DetailsSection>
      </>
    )
  }
}

export default Details